@import "../../styles/variable.scss";
.form-check {
  display: inline-block !important;
  &-input {
    padding: 10px 23px;
    margin-top: 2px;
    margin-right: 5px;
    &:checked {
      background-color: $green !important;
      padding: 10px 23px;
      border-color: $green !important;
    }
  }
}
