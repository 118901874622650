/* ---- START : Theme Colors ---- */
$red: #cc1111;
$green: #17bf38;
$blue: #00adf0;
$yellow: #ffc916;
$grey: #999999;
$orange: #ff9e16;
$black: #000000;
$background: #f4f4f4;
$bg-blue: #d8f2fd;
$bg-secondary: #5f6062;
$cmp-color: #0281bc;
$pale: #eee;
$white: #fff;
/* ---- END : Theme Colors ---- */
