@import "../../styles/variable.scss";

.event-detail {
  .event-name {
    margin-left: 5%;
    font-size: 27px;
    margin-left: 2.5rem;
  }
  .image_div {
    position: relative;
  }

  .banner-description {
    &::first-letter {
      text-transform: capitalize;
    }
  }

  .created-by {
    color: #7e7e7e;
    font: normal normal bold 15px/14px Lato;

    span {
      color: black;
    }
  }

  .wrapper__box {
    min-height: 88vh;
  }

  .date {
    font-size: 15px;
  }

  .detail_page p {
    color: #7e7e7e;
  }

  .banner {
    position: relative;

    .event_form {
      position: absolute;
      bottom: 1rem;
      right: 1rem;
    }

    & > img {
      object-fit: cover;
    }
    .banner_event {
      position: absolute;
      bottom: 15px;
      right: 10px;

      .view-detail {
        position: absolute;
        top: 242px;
        right: 2px;
        width: max-content;
      }
    }

    .cover-image {
      object-fit: cover;
    }

    .spin {
      position: absolute;
      top: 150px;
      left: 50%;
    }

    .spinner-banner {
      height: 300px;
      background-image: url("../../assets/metroimage.png");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }

    &-body {
      > div {
        margin-left: 300px;
      }
      .banner-profile {
        position: absolute;
        top: 190px;
        left: 100px;
        background-color: white;
        padding: 2px;
        width: 200px;
        height: 200px;
        border-radius: 50%;
        object-fit: cover;
        object-position: 50% 50%;
      }
    }

    .dot-spinner {
      justify-content: center;
    }

    &-body {
      > div {
        margin-left: 120px;
      }

      .spiner-design {
        position: absolute;
        top: 170px;
        left: 100px;
        background-image: url("../../assets/metroimage.png");
        padding: 2px;
        width: 200px;
        height: 200px;
        border-radius: 50%;
        object-fit: cover;
        object-position: 50% 50%;
        background-position: center;

        .spin-card {
          position: absolute;
          top: 85px;
          left: 43%;
        }
      }
    }

    .edit-events {
      width: max-content;
      font-size: 1.5rem;
      background-color: white;
      border-radius: 2px;
      right: 24px;
      top: 16px;
    }

    .edit-events:hover {
      cursor: pointer;
    }

    .menu-box {
      width: 17%;
      display: flex;
      flex-direction: column;
      width: 15%;
      background: white;
      text-align: center;
      top: 3.5rem;
      right: 1.3rem;
      font-size: 0.9rem;
      border-radius: 3px;

      button,
      div,
      a {
        &:hover,
        &:focus {
          background-color: #e9ecef !important;
        }
      }

      .menu-box_button {
        padding: 8px 8px;
        border-radius: 0;
        font-size: 15px;
        cursor: pointer;
        &:hover,
        &:focus,
        &:active {
          border-color: transparent;
        }
        &:disabled {
          border-color: transparent;
        }
      }
    }
  }

  .view-detail {
    position: absolute;
    top: 245px;
    right: 10px;
  }
  .publish {
    color: $blue !important;
    border: 1px solid $blue;
    margin-top: 5px;
    margin: 0.5rem;
    padding: 0.2rem;
    padding-left: 0.8rem;
    padding-right: 0.8rem;
    text-transform: uppercase !important;
  }
  .publish:hover {
    // color: white !important;

    background-color: white;
  }

  .event_comments {
    word-break: break-all;
  }

  @media screen and (max-width: 946px) {
    .banner-body {
      > div {
        margin-left: 0px;
        margin-top: 80px !important;
      }
    }

    .event-name {
      margin-left: 1.4rem;
    }

    .banner-profile {
      position: absolute;
      top: 230px !important;
      left: 20px !important;
      background-color: white;
      padding: 2px;
      width: 125px !important;
      height: 125px !important;
      border-radius: 50%;
      object-fit: cover;
      object-position: 50% 50%;
      float: left;
    }
    .banner-description {
      text-align: justify;
      text-indent: 20px;
    }
  }
  .dropdownbox {
    position: absolute;
    right: 10px;
    top: 10px;
    background-color: white;
    border-radius: 5px;
  }
  .button-section {
    color: $blue;
    border: none;
    &:hover,
    &:active,
    &:focus {
      color: $blue;
      border: none;
    }
  }
}
