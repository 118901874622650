@import '../../styles/variable.scss';
.user-details {
  .details {
    border-bottom: 3px solid #f2f2f2;
  }

  .nav-border {
    border-bottom: 3px solid #eee;
    display: flex;
    flex-wrap: nowrap;
    white-space: nowrap;

    a {
      display: inline-block;
      padding: 10px 15px;
      flex-shrink: 0;
    }
  }

  .criteria-title {
    width: 90px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .title-links {
    padding: 11px;
  }

  .wrapper__box {
    min-height: 88vh;
  }

  a {
    color: $black;
    font-weight: 700;
    &:hover {
      color: $black;
    }
  }
}
