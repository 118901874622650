@import '../../styles/variable.scss';

.my-events {
  .title-heading {
    margin-right: 1rem;
    padding: 1rem 1.4rem;
    font-weight: bolder;
    font-size: 1.2rem;
  }

  a {
    text-decoration: none !important;
  }

  .title-heading:hover {
    cursor: pointer;
  }

  .nav-border {
    margin-top: 10px;
    margin-bottom: 2rem;
    border-bottom: 3px solid #eeeeee;
  }

  .content-fit {
    min-height: 88vh;
  }

  .heading {
    font-size: 1.2rem;
    font-weight: 500;
  }
}
