@import "../../styles/variable.scss";
.event-create {
  ul {
    list-style: none;

    li {
      margin: 0 5px;
    }
  }

  .form__nav-bar {
    padding: 1.2rem 1.7rem;
  }

  .stepper-div {
    width: 300px;
  }

  .Mui-completed > svg {
    color: $green !important;
  }

  .Mui-active > svg {
    color: $yellow !important;
  }

  .tool_button {
    ul {
      li {
        border: 2px solid $blue;
        padding: 6px 8px;

        i {
          color: $blue;
        }
      }
    }
  }
  .bi-x,
  .bi-camera {
    cursor: pointer;
  }
  .date-range {
    position: relative;
  }
  .basicDetails-Date-picker {
    position: absolute;
    z-index: 2;
  }
  .basic-details-button {
    padding-top: 2rem;
    padding-bottom: 4rem;
  }
  .Registration-Daterange-picker {
    position: absolute;
    z-index: 2;
  }
  .submission-daterange-picker {
    position: absolute;
    z-index: 2;
  }
  .judgement-daterange-picker {
    position: absolute;
    z-index: 2;
  }
  [class$="option"]:nth-child(even)::before {
    background-color: $green !important;
  }

  [class$="option"]:nth-child(odd)::before {
    background-color: $blue !important;
  }

  .css-z7uhs0-MuiStepConnector-line {
    border-left-style: dashed;
    border-color: $green !important;
  }

  .drop-up .Select-menu-outer {
    top: auto;
    bottom: 100%;
  }

  .cover_photo {
    width: 100%;
    height: 250px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 50% 50%;
      border-radius: 12px;
    }
  }

  .banner_area {
    position: relative;
  }

  .file-upload {
    position: absolute;
    bottom: 12px;
    right: 110px;
    width: 30px;
    height: 30px;
    background-color: $blue;
    text-align: center;
    cursor: pointer;
  }

  .file-upload_dp {
    position: absolute;
    bottom: 15px;
    right: 10px;
    width: 30px;
    height: 30px;
    background-color: $blue;
    z-index: 100;
    text-align: center;
    cursor: pointer;
  }

  #file-input {
    display: none;
  }

  .profile_photo {
    width: 190px;
    height: 190px;
    border-radius: 50%;
    position: absolute;
    top: 30px;
    left: -90px;

    > img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
      object-position: 50% 50%;
      border: 1px solid rgba(0, 0, 0, 0.205);
    }
  }

  @media screen and (min-width: 992px) {
    .cover_photo {
      width: 90%;
    }

    .MuiStep-root {
      position: relative;
    }

    .MuiStepLabel-iconContainer {
      z-index: 100;
    }

    .MuiStepLabel-label {
      position: absolute;
      left: -8px;
      top: -15px;
      width: 280px;

      &.Mui-completed {
        h6 {
          font: normal normal bold 18px/14px Lato;
        }
      }
    }

    .MuiStepLabel-label.Mui-active {
      background-color: $bg-blue;
      padding: 15px 0;
      h6 {
        color: $blue !important;
        font: normal normal bold 18px/14px Lato;
      }
      span {
        font: normal normal normal 12px/14px Lato;
      }
    }
  }
}
