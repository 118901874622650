@import "../../styles/variable.scss";

.judgeModal {
  .score-card {
    position: relative;
    background-color: $white;
    padding: 1.2rem;
    border-radius: 3.5px;
  }

  .close-btn {
    position: absolute;
    right: 1.55rem;
  }

  .score-points {
    width: 70px;
    height: 30px;
    padding: 0.6rem;
    border-radius: 3px;
    font-size: 0.9rem;
    text-align: center;
  }

  .error {
    border: 1px solid $red;
  }

  .name {
    font-size: 0.9rem;
    padding: 1rem 2.3rem;
    text-align: center;
  }

  .title {
    font-size: 0.9rem;
    font-weight: 500;
  }

  .card-heading {
    font-size: larger;
    font-weight: 600;
    text-align: center;
    padding-bottom: 12px;
    margin-bottom: 0;
  }

  .max-score {
    font-weight: bolder;
    font-size: 1rem;
    color: $grey;
  }

  .criteria_box {
    padding: 0.45rem 1.8rem 0.45rem 1rem;
  }

  .criteria {
    font-size: large;
    padding-left: 1.2rem;
    padding-right: 1.2rem;
  }

  .button-type:hover {
    color: $white;
  }

  .score {
    font-size: 14px;
    color: $grey;
  }

  .score-points::-webkit-outer-spin-button,
  .score-points::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .button {
    font-size: 0.95rem;
    width: 110px;
    border-radius: 3.5px;
    color: $white;
  }
}
