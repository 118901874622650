.wrapper {
  height: calc(100% - 50px);
  position: relative;

  .background {
    background: url("../../assets/bg-image.png") no-repeat;
    background-size: cover;
    height: 100%;
  }
}
