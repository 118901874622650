.model-content {
  width: 0;
}

.approver_event {
  width: 33.33%;

  .profile {
    position: relative;
    .profile_div {
      background-color: white;
      padding: 1.5px;
      position: absolute;
      width: 120px;
      height: 120px;
      border-radius: 50%;
      top: -80px;
      & > img {
        top: 100px;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover;
        object-position: 50% 50%;
      }
    }
  }

  .card {
    position: relative;
    box-shadow: rgba(88, 87, 87, 0.15) 1.95px 1.95px 2.6px;

    &-img-top {
      & > img {
        object-fit: cover;
      }
    }
  }

  .font-size {
    font-size: 0.8rem;
  }

  .drop-down-list {
    height: 2.5rem;
  }

  .choose-action {
    border-radius: "2px";
  }

  .description {
    margin-left: 13%;
    padding-left: 16%;
  }

  .description-width {
    gap: 1.2rem;
  }

  .event-font {
    font-size: 0.9rem;
    font-weight: bold;
    padding: 0;
    margin: 0;
  }
}
