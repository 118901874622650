@import 'common.scss';

:root {
  --tabColor: transparent;
}

html,
body {
  font-family: 'Lato', sans-serif;
  margin: 0;
  height: 100%;
  max-width: 100%;
  min-width: 352px !important;
  background-color: $background;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  &:focus {
    outline: 0 !important;
    box-shadow: none !important;
  }
}
.title-heading:focus {
  box-shadow: 0 3px 0 var(--tabColor) !important; // Adjust the box-shadow value as needed
}
#root {
  height: 100%;
}
em {
  font-style: italic;
}
::-webkit-scrollbar {
  width: 0;
  background: transparent;
}

.btn-outline-danger {
  --bs-btn-hover-bg: transparent;
}

.navbar-nav a {
  color: $blue;
  text-decoration: none;
  font-weight: 700 !important;
}

a {
  text-decoration: none;
  color: $black;
  &:hover {
    color: $black;
  }
}

.navbar {
  .user_info {
    span {
      &:nth-child(1) {
        color: $black;
        font: normal normal 600 16px/19px Lato;
      }
      &:nth-child(2) {
        color: #999999;
        font: normal normal normal 12px/15px Lato;
      }
    }
  }
  .nav_name {
    color: $cmp-color;
    font-weight: 600;
    letter-spacing: 2px;
    margin-left: 15px;
  }

  .nav-logo {
    object-fit: cover;
    height: 3rem;
  }
}
.outline {
  background-color: white;
  &:hover,
  &:active {
    background-color: white !important;
  }
}

.card-img-top {
  object-fit: cover;
}

.banner {
  & > img {
    object-fit: cover;
  }
}

.App {
  height: 100%;
}
