.podium-container {
    display: flex;
    justify-content: center;
    align-items: flex-end; 
    margin: 20px;
}

.podium {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 200px; 
    border-radius: 5px 5px 0px 0px;
    margin: 0 0px;
    color: white;
    font-weight: bold;
    transition: height 0.3s;
    background-color: purple;
}

.hexagon {
    width: 60px; 
    height: 35px; 
    color: black;
    text-align: center;
    line-height: 35px;
    position: relative;
    margin-bottom: 10px;
    animation: glow 2s infinite alternate;

    &::before,
    &::after {
        content: '';
        position: absolute;
        width: 0;
        border-left: 30px solid transparent;
        border-right: 30px solid transparent;
        left: 0; 
    }

    &::before {
        bottom: 100%;
        border-bottom: 18px solid rgba(255, 255, 255, 0.8);
    }

    &::after {
        top: 100%;
        border-top: 18px solid rgba(255, 255, 255, 0.8);
    }
}

.gold {
    height: 350px;
    order: 2; 
    box-shadow: 5px 0 10px rgba(0, 0, 0, 0.8), -5px 0 10px rgba(0, 0, 0, 0.7); 
    z-index: 1;
}

.gold .hexagon {
    background-color: gold; 
    animation: gold-glow 0.75s infinite alternate;
}

.gold .hexagon::before {
    border-bottom: 18px solid gold;
}

.gold .hexagon::after {
    border-top: 18px solid gold;
}

.silver {
    height: 275px;
    order: 1; 
}

.silver .hexagon {
    background-color: silver; 
    animation: silver-glow 0.75s infinite alternate;
}

.silver .hexagon::before {
    border-bottom: 18px solid silver; 
}

.silver .hexagon::after {
    border-top: 18px solid silver; 
}

.bronze {
    height: 220px; 
    order: 3;
   
}

.bronze .hexagon {
    background-color: #cd7f32;
    animation: bronze-glow 0.75s infinite alternate; 
}

.bronze .hexagon::before {
    border-bottom: 18px solid #cd7f32;
}

.bronze .hexagon::after {
    border-top: 18px solid #cd7f32;
}


.rank {
    font-size: 20px;
}

.user-name {
    margin: 20px 0 0 0;
    padding: 11px;
    text-align: center;
}

.score {
    font-size: 15px;
}




/* Glowing effect animation */
@keyframes glow {
    0% {
        box-shadow: 0 0 5px rgba(255, 255, 255, 0.8), 0 0 10px rgba(255, 255, 255, 0.8);
    }
    100% {
        box-shadow: 0 0 20px rgba(255, 255, 255, 1), 0 0 50px rgba(255, 255, 255, 1);
    }
}

@keyframes gold-glow {
    0% {
        box-shadow: 0 0 5px gold, 0 0 10px gold;
    }
    100% {
        box-shadow: 0 0 40px gold, 0 0 50px gold;
    }
}

@keyframes silver-glow {
    0% {
        box-shadow: 0 0 5px silver, 0 0 10px silver;
    }
    100% {
        box-shadow: 0 0 40px silver, 0 0 50px silver;
    }
}

@keyframes bronze-glow {
    0% {
        box-shadow: 0 0 5px #cd7f32, 0 0 10px #cd7f32;
    }
    100% {
        box-shadow: 0 0 40px #cd7f32, 0 0 50px #cd7f32;
    }
}

@media (min-width: 768px) {
    .user-name {
        font-size: 25px;
    }
}