@import "../../styles/variable.scss";

.wrapper {
  .card {
    position: relative;

    &-img-top {
      width: 100% !important;
      height: 170px;
    }

    &-body {
      .btn {
        font-size: 12px;
      }
    }

    &-title {
      font: normal normal bold 18px/14px Lato;
    }
    &-text {
      color: #7e7e7e;
      font: normal normal bold 12px/14px Lato;

      span {
        color: $black;
      }
    }

    &-date {
      font-size: 14px;
      font-weight: bold;
    }
  }

  .card-image {
    width: 100% !important;
  }

  a {
    text-decoration: none;
    color: $black;
    cursor: pointer;
    &:hover {
      text-decoration: none;
      color: $black;
      cursor: pointer;
    }
  }

  .profile {
    position: relative;
    .profile_div {
      background-color: $background;
      padding: 1.5px;
      position: absolute;
      width: 120px;
      height: 120px;
      border-radius: 50%;
      top: -80px;
      & > img {
        top: 100px;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover;
        object-position: 50% 50%;
      }
    }
  }

  p {
    margin-bottom: 5px;
  }

  .font-text {
    font-size: 12px;
  }

  .count {
    text-align: center;
    background-color: $bg-secondary;
    font-size: 12px;
    color: white;
    border-radius: 50%;
    width: 26px;
    height: 26px;
    display: inline-block;
    line-height: 25px;
    text-align: center;
  }

  .edit-events {
    width: max-content;
    font-size: 1.5rem;
    background-color: white;
    border-radius: 2px;
    right: 24px;
    top: 16px;
  }

  .edit-events:hover {
    cursor: pointer;
  }

  .menu-box {
    width: 180px;
    background: white;
    text-align: center;
    top: 3.5rem;
    right: 1.3rem;
    font-size: 0.9rem;
    border-radius: 3px;

    .menu-box_button {
      padding: 8px 8px;
      border-radius: 0;
      cursor: pointer;
      &:hover,
      &:focus,
      &:active {
        border-color: transparent;
      }
    }
  }

  .event-status-title {
    background-color: #7e7e7e;
  }

  #add-button {
    border: none;
    border-radius: 5px;
    width: 3rem;
    height: 2.5rem;
    margin-left: 0.5rem;
  }
  .tag-cointainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  #add-button {
    border: none;
    border-radius: 5px;
    width: 3rem;
    height: 2.5rem;
    margin-left: 0.5rem;
  }
  .tag-cointainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
}

@media screen and (min-width: 768px) {
  .profile {
    .card-button {
      position: absolute;
      right: 0px;
    }
  }
}
