.leader-board {
  table {
    //font-family: Arial, Helvetica, sans-serif;
    width: 100%;
    font-family: "Lato";
    font-size: 16px;
    border-collapse: collapse;
    margin-top: 2%;
    margin-left: auto;
    margin-right: auto;

    td {
      border: none;
      padding: 8px;
      text-align: center;
      width: fit-content;
    }

    th {
      padding-top: 12px;
      padding-bottom: 12px;
      text-align: center;
      background-color: #e3f7ff;
      color: black;
      width: fit-content;
    }

    tr {
      font-weight: 400;
    }

    th:first-child {
      text-align: left;
      padding-left: 4%;
    }

    td:first-child {
      text-align: left;
      padding-left: 4%;
    }

    tr:nth-child(even) {
      background-color: #f2f2f2;
    }

    tr:hover {
      background-color: #f2f2f2;
    }
  }

  th:first-child {
    text-align: left;
    padding-left: 4%;
  }

  button {
    background-color: var(--blueColorTheme);
    color: white;
  }

  input {
    border: none;
    border: 1px solid;
    border-radius: 6% 6%;
    border-color: #f2f2f2;
    border-radius: 5px 5px 5px 5px !important;
    width: 20rem;
    padding: 0.5rem;
    padding-left: 2rem;
  }

  .searchText {
    color: black;
    padding-left: 0 !important;
    padding: 15px;
    border-radius: 5px;
    div {
      i {
        left: 10px;
        top: 10px;
      }
    }
  }

  .view_user {
    padding: 2rem;
    box-shadow: 10px 10px 85px 0px rgba(0, 0, 0, 0.1);
    font-weight: 700;
  }

  .details {
    margin-left: 0rem;
    padding-top: 1.5rem;
    font-weight: 700;
  }

  h5 {
    padding-left: 30%;
    font-size: 1rem;
    font-weight: 700;
  }

  .nav-link {
    color: black !important;
    padding-left: 2px;
  }

  .nav-link:hover {
    color: var(--bs-link-hover-color) !important;
  }

  .export {
    display: none;
  }

  hr {
    margin-top: 0;
    margin-bottom: 1rem;
    border: 0;
    border-top: 0.1rem solid rgba(0, 0, 0, 0.5);
  }

  h3 {
    font-size: 1.5rem;
    font-weight: 700;
  }

  .leadBoard:hover::after {
    content: "";
    position: fixed;
    left: 25%;
    right: 67%;
    bottom: 76.6%;
    border: 1px solid #00adf0;
  }

  .first-medal {
    color: #ffd700;
  }

  .second-medal {
    color: #c0c0c0;
  }

  .third-medal {
    color: #804a00;
  }
  .publish {
    color: #00adf0 !important;
    border: 1px solid #00adf0;
    margin-top: 0px !important;
    margin: 0.5rem;
    padding: 0.2rem;
    padding-left: 0.8rem;
    padding-right: 0.8rem;
    text-transform: capitalize;
  }
  .publish:hover {
    background-color: white;
  }
  p {
    padding-left: 0.5rem;
  }
  a {
    color: #00adf0 !important;
    font-weight: 400 !important;
  }
  .export-button {
    align-items: center !important;
    background-color: white;
  }
  .export-button:hover {
    background-color: white;
  }
  .export-csv {
    color: #f2f2f2;
  }
}
.podium-width
{
  width: 35% !important;
}
.table-width
{
  width: 65% !important;
}
@media (max-width: 768px) {
  .podium-width
{
  width: 100% !important;
}
.table-width
{
  width: 100% !important;
}
}