@import "../../styles/variable.scss";
.table-div {
  table {
    width: 100%;
    font-family: "Lato";
    font-size: 16px;
    border-collapse: collapse;
    margin-top: 2%;
    margin-left: auto;
    margin-right: auto;
    td {
      border: none;
      padding: 8px;
      text-align: center;
      width: fit-content;
    }
    th {
      padding-top: 12px;
      padding-bottom: 12px;
      text-align: center;
      background-color: #e3f7ff;
      color: black;
      width: fit-content;
    }
    tr {
      font-weight: 400;
      text-transform: capitalize;
    }
    th:first-child {
      text-align: left;
      padding-left: 4%;
    }
    td:first-child {
      text-align: left;
      padding-left: 4%;
    }
    tr:nth-child(even) {
      background-color: #f2f2f2;
    }
    tr:hover {
      background-color: #f2f2f2;
    }
  }
  th:first-child {
    text-align: left;
    padding-left: 4%;
  }

  button {
    background-color: $blue;
    color: white;
  }

  input {
    border: none;
    border: 1px solid;
    border-radius: 6% 6%;
    border-color: #f2f2f2;
    border-radius: 5px 5px 5px 5px !important;
    width: 20rem;
    padding: 0.5rem;
    padding-left: 2rem;
  }
  .searchText {
    color: black;
    margin-top: 2%;
    background-color: #f2f2f2;
    padding-left: 0 !important;
    padding: 15px;
    border-radius: 5px;
    div {
      i {
        left: 10px;
        top: 10px;
      }
    }
  }

  .view_user {
    padding: 2rem;
    box-shadow: 10px 10px 85px 0px rgba(0, 0, 0, 0.1);
    font-weight: 700;
  }

  .details {
    margin-left: 0rem;
    padding-top: 1.5rem;
    font-weight: 700;
  }

  .nav-link {
    color: black !important;
    padding-left: 2px;
  }
  .nav-link:hover {
    color: var(--bs-link-hover-color) !important;
  }
  .export {
    display: none;
  }
  hr {
    margin-top: 0;
    margin-bottom: 1rem;
    border: 0;
    border-top: 0.1rem solid rgba(0, 0, 0, 0.5);
  }
  .registration:hover::after {
    content: "";
    position: fixed;
    left: 8.5%;
    right: 82%;
    bottom: 75.5%;
    border: 1px solid #00adf0;
  }
  .submission:hover::after {
    content: "";
    position: fixed;
    left: 18%;
    right: 73%;
    bottom: 75.5%;
    border: 1px solid #00adf0;
    tr:hover {
      background-color: #f2f2f2;
    }
    h3 {
      font-size: 2rem;
      font-weight: 700;
    }
    .top-10 {
      margin-left: 60rem;
      border-radius: 50px;
    }
    .leadBoard:hover::after {
      content: "";
      position: fixed;
      left: 27%;
      right: 66.5%;
      bottom: 75.5%;
      border: 1px solid #00adf0;
    }
  }
  th:first-child {
    text-align: left;
    padding-left: 4%;
  }
  button {
    color: white;
  }
  input {
    border: none;
    border: 1px solid;
    border-radius: 6% 6%;
    border-color: #f2f2f2;
    border-radius: 5px 5px 5px 5px !important;
    width: 20rem;
    padding: 0.5rem;
    padding-left: 2rem;
  }
  .searchText {
    color: black;
    margin-top: 2%;
    background-color: #f2f2f2;
    padding: 15px;
    border-radius: 5px;
  }

  .view_user {
    padding: 2rem;
    box-shadow: 10px 10px 85px 0px rgba(0, 0, 0, 0.1);
    font-weight: 700;
  }
  .details {
    margin-left: 0rem;
    padding-top: 1.5rem;
    font-weight: 700;
  }
  h5 {
    padding-left: 30%;
    font-size: 1rem;
    font-weight: 700;
  }
  .nav-link {
    color: black !important;
    padding-left: 2px;
  }
  .nav-link:hover {
    color: var(--bs-link-hover-color) !important;
  }
  hr {
    margin-top: 0;
    margin-bottom: 1rem;
    border: 0;
    border-top: 0.1rem solid rgba(0, 0, 0, 0.5);
  }
  .export {
    display: none;
  }

  .registration:hover::after {
    content: "";
    position: fixed;
    left: 8%;
    right: 83%;
    bottom: 76.6%;
    border: 1px solid #00adf0;
  }
  .submission:hover::after {
    content: "";
    position: fixed;
    left: 17%;
    right: 75%;
    bottom: 76.6%;
    border: 1px solid #00adf0;
  }
  .lowercase {
    text-transform: lowercase;
  }
}
