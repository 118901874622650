@import "../../styles/variable.scss";

#login_container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  button {
    background-color: $blue;
    width: 100%;
  }

  h4 {
    font: normal normal 900 24px/29px Lato;
  }

  p {
    font: normal normal normal 14px/17px Lato;
    color: $bg-secondary;
  }
}

@media screen and (min-width: 352px) and (max-width: 641px) {
  #login_container {
    width: 90%;
  }
}
