@import "../../styles/variable.scss";

.tox-notification {
  display: none !important;
}
.tox .tox-statusbar__branding svg {
  display: none !important;
}
.tox .tox-toolbar__group {
  border-left: 2px solid $pale;
  border-right: 0px;
}
.tox:not(.tox-tinymce-inline) .tox-editor-header {
  border: 2px solid $pale;
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  padding: 0 0 !important;
}
.tox-toolbar__primary div:first-child {
  border-left: 0px;
}
.tox .tox-statusbar {
  display: none !important;
}
