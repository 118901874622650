@import "../../styles/variable.scss";
.form-generator {
  width: 70%;
  button {
    margin-bottom: 10px;
  }
  .banner {
    margin-top: 10px;
    position: relative;
    i {
      position: absolute;
      right: 10px;
      top: 10px;
      color: $bg-blue;
      background-color: $cmp-color;
      border-radius: 50%;
      padding: 0px 3.8px;
    }
    i:hover {
      cursor: pointer;
    }
  }
  .form_element {
    width: 80%;
    position: relative;
    top: -30px;
    .border_top {
      height: 10px;
    }
    .btn-toolbar {
      padding-bottom: 0.5rem;
      float: right;
    }
    .btn-file-upload-clear {
      border: solid;
      color: red;
      margin-left: 0;
    }
    .btn-image-clear {
      border: solid;
      color: red;
      margin-left: 0;
    }
  }
  .wrapper__box {
    min-height: 88vh;
  }
  .file-upload-preview {
    div:nth-child(2) {
      margin-left: 0 !important;
    }
  }
}
