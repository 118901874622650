@import "../../../styles/variable.scss";
.submission_form {
  .submission_banner {
    background-color: $bg-blue !important;
  }
  .form_element {
    width: 80%;
    position: relative;
    top: -30px;
    .border_top {
      height: 10px;
    }
    .btn-toolbar {
      padding-bottom: 0.5rem;
      float: right;
    }
    .btn-file-upload-clear {
      color: red;
      border: solid;
    }
    .btn-image-clear {
      color: red;
      border: solid;
    }
  }
  .wrapper__box {
    min-height: 88vh;
  }
}
