.dashboard {
  .heading {
    font-size: 1.2rem;
    font-weight: 500;
  }

  .wrapper__box {
    min-height: 88vh;
  }

  .nav-title {
    border-bottom: 3px solid #eeeeee;
    margin-top: 10px;
    margin-bottom: 2rem;
    padding-bottom: 2px;
  }
}
