.submission_answers {
  .banner {
    position: relative;
    button {
      position: absolute;
      right: 10px;
      top: 10px;
    }
  }
}
